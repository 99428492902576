<script lang="ts" setup>
import { CfgDropdown, CfgTableMenuItem, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { ChevronDownIcon } from '@cfgtech/icons'
import ForCompaniesDropdown from '../NavBar/ForCompaniesDropdown.vue'
import FormSearchInput from '../Form/Search/input.vue'
import type { RouteLocationRaw } from '#vue-router'
import { paths } from '~/constants/paths'
import { useIssuesSearch } from '~/composables/issues/useIssuesList/useIssuesSearch'

type HeadRoute = {
  title: string
  path?: RouteLocationRaw
  abRisk?: 'a' | 'b'
  handleClick?: () => void
  dropdownItems?: HeadRoute[]
  component?: any
  componentProps?: Record<string, any>
  dropdownComponent?: any
}

const { t } = useI18n()

const route = useRoute()
const router = useRouter()
const { onCompanyPageClick } = useDataLayer()
const { setSearch } = useIssuesSearch()

const forIssuersDropdownRef = ref<typeof CfgDropdown | null>(null)

const nav = computed<HeadRoute[]>(() => Object.values({
  home: {
    title: t('nav.home'),
    path: '/dluhopisy/firemni',
    handleClick() {
      nextTick(() => {
        router.push({
          path: '/dluhopisy/firemni',
        })
      })
    },
  },
  companyList: {
    title: t('nav.companyList'),
    path: '/emitenti',
    handleClick() {
      onCompanyPageClick(DataLayerLocationsEnum.Header)
    },
  },

  aboutUs: {
    title: t('nav.aboutUs'),
    path: '/o-nas',
  },

  contact: {
    title: t('nav.contact'),
    path: '/kontakt',
  },

  search: {
    title: t('nav.search'),
    component: FormSearchInput,
    componentProps: {
      placeholder: 'Hledat',
      onSubmit: (search: string) => {
        if (`${paths.corporateIssues}/`) {
          router.push({ ...route, path: `${paths.corporateIssues}/`, query: { search: search.trim() } })
        }

        setSearch(search)
      },
    },
  },

  forCompaniesDropdown: {
    title: t('nav.forIssuers'),
    dropdownComponent: ForCompaniesDropdown,
  },
}))

function closeForIssuersDropdown() {
  if (forIssuersDropdownRef.value?.[0]) {
    forIssuersDropdownRef.value?.[0].close()
  }
}
</script>

<template>
  <nav :aria-label="$t('nav.topAriaLabel')">
    <slot :menu="nav">
      <ul class="flex items-center lg:justify-start lg:gap-x-3">
        <li
          v-for="({ path, title, abRisk, handleClick, dropdownItems, dropdownComponent, component: itemComponent, componentProps }, index) in nav"
          :key="`${title}_${index}`"
          class="navbar-item shrink-0 grow lg:grow-0 lg:px-4"
          :class="{ 'xl:ml-auto xl:pr-0': index === nav.length - 1 }"
          :data-ab-risk="abRisk"
          :style="`--basis: ${100 / nav.length}%`"
        >
          <component :is="itemComponent" v-bind="componentProps" v-if="itemComponent" />

          <!-- CUSTOM DROPDOWN CONTENT COMPONENT -->
          <CfgDropdown
            v-else-if="dropdownComponent"
            ref="forIssuersDropdownRef"
            hide-on-scroll
            max-width=""
          >
            <template #trigger="{ opened }">
              <span class="flex items-center gap-x-1 hover:text-brand" :class="{ 'text-brand': opened, 'text-grey-400': !opened }">
                <span class="xs-text font-display leading-tight sm:text-sm">{{ title }} </span>

                <ClientOnly>
                  <span
                    class="shrink-0 transition-all duration-300 hover:text-brand"
                    :class="{
                      'rotate-180 text-brand': opened,
                      'text-grey-300': !opened,
                    }"
                  >
                    <ChevronDownIcon />
                  </span>
                </ClientOnly>
              </span>
            </template>

            <template #default="">
              <component :is="dropdownComponent" @close="closeForIssuersDropdown" />
            </template>
          </CfgDropdown>

          <!-- DROPDOWN CONTENT DEFINED IN VARIABLE -->
          <CfgDropdown v-else-if="dropdownItems?.length" hide-on-scroll>
            <template #trigger="{ opened }">
              <span class="xs-text font-display leading-tight hover:text-brand sm:text-sm" :class="{ 'text-brand': opened, 'text-grey-400': !opened }">{{ title }}</span>
            </template>

            <template #default="">
              <div class="flex flex-col gap-y-2 px-4 py-3">
                <CfgTableMenuItem
                  v-for="({ path: childPath, title: childTitle, abRisk: childAbRisk, handleClick: childHandleClick }, iindex) in dropdownItems"
                  :key="`${childTitle}_${iindex}`"
                  as="span"
                  class="h-6"
                  :data-ab-risk="childAbRisk"
                  @click="childHandleClick?.()"
                >
                  <template #body>
                    <NuxtLink
                      active-class="text-brand"
                      :aria-label="childTitle"
                      class="text-center text-grey-400 transition-colors duration-200 hover:text-brand"
                      :class="{
                        '!text-brand [&>span]:!font-highlighted': childPath === childPath && $route.path === childPath,
                      }"
                      :to="childPath"
                    >
                      <CfgTypography class="!" :size="CfgTypographySizes.xs">
                        {{ childTitle }}
                      </CfgTypography>
                    </NuxtLink>
                  </template>
                </CfgTableMenuItem>
              </div>
            </template>
          </CfgDropdown>

          <!-- DEFAULT LINK -->
          <NuxtLink
            v-else
            active-class="!text-brand [&>span]:!font-highlighted"
            class="flex flex-col items-center justify-center gap-y-2 text-center text-grey-400 transition-colors duration-200 hover:text-brand"
            :to="path"
            @click="handleClick?.()"
          >
            <span class="xs-text font-display leading-tight sm:text-sm">{{ title }}</span>
          </NuxtLink>
        </li>
      </ul>
    </slot>
  </nav>
</template>

<style lang="scss" scoped>
.navbar-item {
  flex-basis: var(--basis);

  @screen lg {
    flex-basis: auto;
  }
}
</style>
