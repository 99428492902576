<script setup lang="ts">
import { CfgButton, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { RouteLocationRaw } from '#vue-router'

type HeadRoute = {
  title: string
  path?: RouteLocationRaw
  handleClick?: () => void
  variant?: 'button-primary' | 'button-secondary' | 'description'
}

type RotueColumn = {
  title: string
  routes: HeadRoute[]
}

const emit = defineEmits<{
  close: []
}>()

const { t } = useI18n()

const { app: { ISSUER_APP_URL } } = useRuntimeConfig()

const nav = computed<RotueColumn[]>(() => [
  {
    title: t('nav.forCompanies.overview'),
    routes: [
      {
        title: t('nav.forCompanies.offerings'),
        path: '/pro-emitenty',
      },
      {
        title: t('nav.forCompanies.consultation'),
        path: '/poradna-pro-emitenty',
      },
      // {
      //   title: t('nav.forCompanies.caseStudies'),
      //   path: 'todo',
      // },
      {
        title: t('nav.forCompanies.pricing'),
        path: '/cenik',
      },
    ],
  },
  {
    title: t('nav.forCompanies.issues'),
    routes: [
      {
        title: t('nav.forCompanies.wantToRelease'),
        path: '/jak-vydat-dluhopis',
      },
      {
        title: t('nav.forCompanies.haveReleased'),
        path: '/pro-emitenty/propagace-dluhopisu',
      },
      // {
      //   title: t('nav.forCompanies.haveSubscribed'),
      //   path: 'todo',
      // },
    ],
  },
  {
    title: t('nav.forCompanies.myAccount'),
    routes: [
      {
        title: t('nav.forCompanies.manageIssue'),
        variant: 'description',
      },
      {
        title: t('nav.forCompanies.login'),
        path: `${ISSUER_APP_URL}/auth/login`,
        variant: 'button-secondary',
      },
      {
        title: t('nav.forCompanies.register'),
        path: `${ISSUER_APP_URL}/auth/register`,
        variant: 'button-primary',
      },
    ],
  },
])
</script>

<template>
  <div class="pb-12 sm:pb-2 lg:p-6">
    <ul class="flex flex-wrap gap-x-14 gap-y-10 lg:flex-row [&>li]:flex-1">
      <li
        v-for="column in nav"
        :key="column.title"
        class="space-y-3"
      >
        <CfgTypography class="whitespace-nowrap !font-highlighted" :size="CfgTypographySizes.md">
          {{ column.title }}
        </CfgTypography>

        <ul
          class="flex flex-col gap-y-1"
        >
          <li
            v-for="{ title, path, variant } in column.routes"
            :key="title"
            class="flex whitespace-nowrap [&>*]:w-full"
          >
            <NuxtLink
              v-if="variant === 'button-primary'"
              :to="path"
              @click="emit('close')"
            >
              <CfgButton
                as="span"
                class="w-full whitespace-nowrap"
                small
                variant="primary"
              >
                {{ title }}
              </CfgButton>
            </NuxtLink>

            <NuxtLink
              v-else-if="variant === 'button-secondary'"
              :to="path"
              @click="emit('close')"
            >
              <CfgButton
                as="span"
                class="w-full whitespace-nowrap"
                flat
                small
                stroke
                variant="primary"
              >
                {{ title }}
              </CfgButton>
            </NuxtLink>

            <span v-else-if="variant === 'description'" class="xs-text mb-2 font-display leading-tight sm:text-sm">{{ title }}</span>

            <NuxtLink
              v-else
              active-class="!text-brand [&>span]:!font-highlighted"
              class="text-grey-400 transition-colors duration-200 hover:text-brand"
              :to="path"
              @click="emit('close')"
            >
              <span class="xs-text font-display leading-tight sm:text-sm">{{ title }}</span>
            </NuxtLink>
          </li>
        </ul>
      </li>

      <li>
        <NavBarContactUs />
      </li>
    </ul>
  </div>
</template>
