<script setup lang="ts">
import { CfgDialog, CfgModalSizes, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { objectEntries } from '@vueuse/core'
import type { ContactForm, ContactFormSchema } from '~/components/Form/Contact/types'
import type { FormPresetExposed } from '~/components/Form/Presets/types'
import { contactForwardIssuerSubmit } from '~/components/Form/Subscribe/factory/api'
import { ISSUE_URL } from '~/constants/issue'

const { issuerData, isContactIssuerForwardDialogOpened } = useContactIssuerForward()

const { t } = useI18n()
const config = useRuntimeConfig()

const formRef = ref<FormPresetExposed | null>(null)
const pending = ref(false)

const sentStates = reactive({ success: false, error: false })
const sendState = computed(() => objectEntries(sentStates).find(([_, value]) => Boolean(value))?.[0] || null)

const isStatusModalOpened = ref(false)

const contactFormSchema: ContactFormSchema = {
  phone: { show: true, required: true },
  email: { show: true, required: false },
}

async function handleFormSubmit(results: ContactForm) {
  pending.value = true
  try {
    if (Object.entries(issuerData.value).some(([, value]) => !value)) {
      throw new Error('issueName, issueSlug, companyName should not be null')
    }

    const url = `${config.app.ORIGIN}/${ISSUE_URL}/${issuerData.value.issueSlug}`

    await contactForwardIssuerSubmit({
      ...results,
      ...(results.email?.trim()?.length ? { email: results.email } : {}),
      note: t('contact.issuerForwardDialog.note', {
        issueName: issuerData.value.issueName,
        url,
      }),
    })

    isContactIssuerForwardDialogOpened.value = false
    handleSendSuccess()
  }
  catch (_) {
    isContactIssuerForwardDialogOpened.value = false
    handleSendError()
  }
  finally {
    pending.value = false
  }
}

function handleSendSuccess() {
  sentStates.success = true
  isStatusModalOpened.value = true
}

function handleSendError() {
  sentStates.success = false
  sentStates.error = true
  isStatusModalOpened.value = true
}
</script>

<template>
  <CfgDialog v-model:opened="isContactIssuerForwardDialogOpened" :size="CfgModalSizes.Medium">
    <template #title>
      <CfgTypography :size="CfgTypographySizes.h2">
        {{ $t('contact.issuerForwardDialog.title') }}
      </CfgTypography>
    </template>

    <template #description>
      <CfgTypography :size="CfgTypographySizes.sm">
        {{ $t('contact.issuerForwardDialog.description') }}
      </CfgTypography>
    </template>

    <FormPresetsContactForm
      ref="formRef"
      :button-label="$t('contact.issuerForwardDialog.button.send.label')"
      :form-schema="contactFormSchema"
      :pending="pending"
      @submit="handleFormSubmit"
    />
  </CfgDialog>

  <ClientOnly>
    <LazyFormSendStateProvider
      v-model:isOpened="isStatusModalOpened"
      :success-description="$t('contact.issuerForwardDialog.success.description', {
        companyName: issuerData.companyName,
      })"
      :success-title="$t('issue.heroSection.leadDialog.success.title')"
      :type="sendState"
    />
  </ClientOnly>
</template>
