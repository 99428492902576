import { useRouteQuery } from '@vueuse/router'

const issuerCompanyIdKey = 'issuerCompanyId'

export function useIssuerSectionBanner() {
  const { onSrovIssuerOpen, onSrovSignUpClick } = useDataLayer()

  const isIssuerVisitLogged = useState('is-issuer-visit-logged', () => false)
  const issuerCompanyIdCookie = useCookie(issuerCompanyIdKey)
  const issuerCompanyIdQuery = useRouteQuery(issuerCompanyIdKey)

  const isIssuerSectionBannerShown = computed(() => Boolean(issuerCompanyIdQuery.value || issuerCompanyIdCookie.value))

  function handleCompanyIdCookie() {
    if (!issuerCompanyIdQuery.value) {
      return
    }

    issuerCompanyIdCookie.value = issuerCompanyIdQuery.value as string
  }

  function handleBannerClick() {
    onSrovSignUpClick(DataLayerLocationsEnum.TopAlert, window.location.href)
  }

  watch(issuerCompanyIdQuery, () => {
    handleCompanyIdCookie()
  }, { immediate: true })

  onMounted(() => {
    handleCompanyIdCookie()

    if (isIssuerSectionBannerShown.value && !isIssuerVisitLogged.value) {
      onSrovIssuerOpen()
      isIssuerVisitLogged.value = true
    }
  })

  return { isIssuerSectionBannerShown, handleBannerClick }
}
