<script lang="ts" setup>
import type { CfgFormExposed } from '@cfgtech/ui'

const modalSignUpIssueRef = ref<Omit<CfgFormExposed, 'submit'> | null>(null)

const {
  dialogOpenedMap,
  formSendSuccess,
  openSuccessModal,
  openErrorModal,
} = useSignUpIssue()

function handleAfterCloseAnimationForm() {
  if (formSendSuccess.value === null) {
    return
  }

  if (formSendSuccess.value) {
    nextTick(() => openSuccessModal())
  }
  else {
    nextTick(() => openErrorModal())
  }

  formSendSuccess.value = null
}
</script>

<template>
  <ModalSignUpIssueForm
    ref="modalSignUpIssueRef"
    v-model:is-opened="dialogOpenedMap.form"
    @after-close-animation="handleAfterCloseAnimationForm"
  />

  <ModalSignUpIssueSuccess v-model:is-opened="dialogOpenedMap.success" />

  <ModalSignUpIssueError v-model:is-opened="dialogOpenedMap.error" />
</template>
