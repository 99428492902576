<script lang="ts" setup>
import { CfgButton, CfgDialog, CfgModalSizes, CfgTypography, CfgTypographySizes, type FormResults } from '@cfgtech/ui'
import type { AddIssueSchema } from '~/components/Form/SignUp/types'
import { leadSubmit } from '~/components/Form/Subscribe/factory/api'
import { useSentry } from '~/composables/sentry/useSentry'

defineEmits<{
  'after-close-animation': []
}>()

const { formSendSuccess, signUpEmail, formType, resolvedSource, resolvedEvent, formOpenedFrom, isHowItWorksButtonVisible, openedIssue } = useSignUpIssue()
const { trackEvent } = useDataLayer()

const isOpened = defineModel<boolean>('isOpened', {
  default: false,
  type: Boolean as PropType<boolean>,
})

const isOpenedFromIssueDetail = computed(() => formType.value === SignUpModalType.default && openedIssue.value !== null)

const isLoading = ref(false)
const route = useRoute()
const { t } = useI18n()
const openingHours = useOpeningHours()
async function handleSubmit(results: FormResults<AddIssueSchema>) {
  try {
    isLoading.value = true
    signUpEmail.value = results.email

    trackEvent(resolvedEvent.value, { location: formOpenedFrom.value || DataLayerLocationsEnum.PricingTable, path: route.path })

    await leadSubmit({
      email: results.email,
      ...(results.phone !== '' && {
        phone: results.phone,
      }),
      note: `Žádost o doplnění emise: ${openedIssue.value?.name || results.nameOrIsin}`,
    }, resolvedSource.value)

    formSendSuccess.value = true
  }
  catch (err) {
    formSendSuccess.value = false
    useSentry().captureException(err)
  }
  finally {
    isOpened.value = false
    isLoading.value = false
  }
}
</script>

<template>
  <CfgDialog
    v-model:opened="isOpened"
    :size="CfgModalSizes.Medium"
    @after-close-animation="$emit('after-close-animation')"
  >
    <template #title>
      <CfgTypography :size="CfgTypographySizes.h2">
        {{ $t(`issue.signUpIssue.${formType}.title`) }}
      </CfgTypography>
    </template>

    <template #description>
      <CfgTypography v-if="isOpenedFromIssueDetail" :size="CfgTypographySizes.md">
        {{ $t(`issue.signUpIssue.${formType}.descriptionWithIssue`) }}
      </CfgTypography>

      <CfgTypography v-else :size="CfgTypographySizes.md">
        {{ t(`issue.signUpIssue.${formType}.${openingHours ? 'descriptionHour' : 'description'}`) }}
      </CfgTypography>
    </template>

    <FormSignUpIssue
      :hide-issue-input="isOpenedFromIssueDetail"
      :is-loading="isLoading"
      @submit="handleSubmit"
    />

    <CfgButton
      v-if="isHowItWorksButtonVisible"
      class="mt-3 w-full"
      stroke
      @click="$router.push('/pro-emitenty'); isOpened = false;"
    >
      {{ $t('issue.signUpIssue.howItWorks') }}
    </CfgButton>
  </CfgDialog>
</template>
