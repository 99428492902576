<script lang="ts" setup>
import { FacebookIcon, MailIcon } from '@cfgtech/icons'
import type { FunctionalComponent } from 'nuxt/dist/app/compat/capi'

const smmList: Array<{
  href: string
  name: string
  icon: FunctionalComponent
}> = [
  {
    name: 'facebook',
    href: 'https://www.facebook.com/srovnavacdluhopisu',
    icon: FacebookIcon,
  },
  {
    name: 'email',
    href: 'mailto:info@srovnavacdluhopisu.cz',
    icon: MailIcon,
  },
]
</script>

<template>
  <ul class="flex gap-[0.2em]">
    <li v-for="{ href, icon, name } in smmList" :key="name">
      <a
        :aria-label="name"
        class="flex size-10 items-center justify-center lg:transition-colors lg:duration-300 lg:hover:text-brand"
        :href="href"
        target="_blank"
      >
        <ClientOnly>
          <span class="text-[1.1em]">
            <Component :is="icon" />
          </span>

          <template #fallback>{{ name }}</template>
        </ClientOnly>
      </a>
    </li>
  </ul>
</template>
