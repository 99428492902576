export function useStickyElementIsShown() {
  const shown = useState(`sticky-element-show-${useUniqueId()}`, () => false)

  if (process.client) {
    const { directions, arrivedState, isScrolling } = useScroll(window)

    watch([arrivedState, directions], () => {
      // show element when is top
      if (arrivedState.top) {
        shown.value = true
        return
      }

      // nothing to do while scrolling
      if (!isScrolling.value) {
        return
      }

      // show element when user scroll to top
      shown.value = directions.top
    })
  }

  return shown
}
