<script setup lang="ts">
import { CfgButton, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { ISSUER_SECTION_URL } from '~/constants/common'

const emit = defineEmits<{
  click: []
}>()
</script>

<template>
  <div class="fixed top-0 z-10 flex h-[8.25rem] w-full max-w-[100vw] flex-col items-center justify-center gap-x-4 gap-y-3 bg-brand-opacity p-3 text-white sm:h-[3.75rem] sm:flex-row">
    <CfgTypography
      class="!font-highlighted"
      :html="$t('appBanner.title')"
      :size="CfgTypographySizes.md"
    />

    <CfgButton
      as="a"
      class=" w-full !bg-white !text-brand sm:w-fit"
      flat
      :href="ISSUER_SECTION_URL"
      @click="emit('click')"
    >
      <span class="mx-4 block py-3 lg:py-1">
        {{ $t('appBanner.action') }}
      </span>
    </CfgButton>
  </div>

  <!-- TO PREVENT CONTENT FROM BEING COVERED BY BANNER -->
  <div class="h-[8.25rem] sm:h-[3.75rem]" />
</template>
