<script lang="ts" setup>
import { ChevronDownIcon, MenuIcon, ProfileCircleIcon, SearchIcon, XMarkIcon } from '@cfgtech/icons'
import { CfgButton, CfgDropdown, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { onClickOutside, useElementBounding } from '@vueuse/core'
import { useIssuesSearch } from '~/composables/issues/useIssuesList/useIssuesSearch'
import { useStickyElementIsShown } from '~/composables/useStickyElementIsShown'
import { paths } from '~/constants/paths'

defineProps<{ isIssuerSectionBannerShown: boolean }>()

// STORES
const appStore = useAppStore()

// REFS
const mobileNavigation = templateRef<HTMLElement>('mobileNavigation')

const headerNode = templateRef<HTMLElement>('header')
onClickOutside(headerNode, handleCloseMobileMenu)

const { bottom: headerBottomLocation, top: headerTopLocation, height } = useElementBounding(headerNode)

const documentRef = ref<Document | null>(null)

// DATA
const isMobileMenuOpened = ref(false)
const openedMobileMenuAccordionItem = ref<number>(-1)
const isMobileSearchOpened = ref(false)
const searchValue = ref('')

// COMPOSABLES
const router = useRouter()
const route = useRoute()
const { arrivedState, y } = useScroll(documentRef)
const isStickyElementShown = useStickyElementIsShown()
const responsive = useResponsive()
const { public: { investorAppUrl } } = useRuntimeConfig()
const overlay = useOverlay()
const { setSearch } = useIssuesSearch()

// COMPUTED
const appBody = computed(() => documentRef.value?.body || null)

const isHeaderShown = computed(() => isStickyElementShown.value || arrivedState.top)

// methods
function handleToggleSearch() {
  isMobileMenuOpened.value = false
  isMobileSearchOpened.value = !isMobileSearchOpened.value
}

function handleToggleMenu() {
  isMobileSearchOpened.value = false
  isMobileMenuOpened.value = !isMobileMenuOpened.value
}

// Set boundings
const { height: mobileNavigationHeight } = useElementBounding(mobileNavigation)
const mobileNavigationHeightCss = useCssVar(
  '--mobile-navigation-height',
  appBody,
)

// CSS VARIABLES FOR HEADER SIZE AND POSITION
if (process.client) {
  watch(height, () => {
    document.documentElement?.style.setProperty(
      '--header-height',
      `${height.value}px`,
    )
  }, { immediate: true })

  watch([headerBottomLocation, isHeaderShown], () => {
    document.documentElement?.style.setProperty(
      '--header-bottom-location',
      `${isHeaderShown.value ? headerBottomLocation.value : headerTopLocation.value}px`,
    )
  }, { immediate: true })
}

watch(
  mobileNavigationHeight,
  () => (mobileNavigationHeightCss.value = `${mobileNavigationHeight.value}px`),
  { immediate: true },
)

watchEffect(() => {
  appStore.setHeaderShown(Boolean(isHeaderShown.value || isMobileMenuOpened.value || isMobileSearchOpened.value))
  overlay.value = isMobileMenuOpened.value || isMobileSearchOpened.value
})

onBeforeMount(() => {
  documentRef.value = document

  nextTick(() => {
    documentRef.value?.dispatchEvent?.(new Event('scroll'))
  })
})

function handleCloseMobileMenu() {
  isMobileMenuOpened.value = false
  isMobileSearchOpened.value = false
}

function handleFormSearchInputSubmit() {
  if (`${paths.corporateIssues}/`) {
    router.push({ ...route, path: `${paths.corporateIssues}/`, query: { search: searchValue.value.trim() } })
  }

  setSearch(searchValue.value)
  handleCloseMobileMenu()
  searchValue.value = ''
}

// Investor Section
const userName = useUserName()
</script>

<template>
  <!-- Keep only top-0 class after removing IssuerSectionBanner  -->
  <!-- eslint-disable vue/no-unused-refs -->
  <header
    ref="header"
    class="page-header sticky z-sticky overflow-hidden border-b border-b-transparent transition-all duration-500"
    :class="{
      'border-b-grey-stroke ': y > 0,
      'top-0': !isIssuerSectionBannerShown,
      'top-[8.25rem] sm:top-[3.75rem]': isIssuerSectionBannerShown,
    }"
  >
    <!-- eslint-enable vue/no-unused-refs -->
    <div
      class="container mx-auto bg-white py-4 transition-all duration-500 md:py-5"
      :class="{
        '-translate-y-full': !appStore.isHeaderShown,
      }"
    >
      <div class="flex items-center">
        <NuxtLink class="flex-none" to="/">
          <AppLogo data-cy="appLogo" />
        </NuxtLink>

        <div class="hidden w-full items-center gap-x-5 xl:flex">
          <div class="w-full pl-12 xl:block">
            <LayoutNavigation />
          </div>

          <div class="flex flex-none self-stretch">
            <div class="h-2/3 w-0.5 self-center rounded-sm bg-grey-stroke" />
          </div>

          <CfgDropdown v-if="userName" hide-on-scroll>
            <template #trigger="{ opened }">
              <div class="flex items-center gap-2 text-brand hover:text-brand-dark">
                <ProfileCircleIcon />

                {{ userName }}

                <ClientOnly>
                  <span
                    class="shrink-0 transition-all duration-300 hover:text-brand"
                    :class="{
                      'rotate-180 text-brand': opened,
                      'text-grey-300': !opened,
                    }"
                  >
                    <ChevronDownIcon />
                  </span>
                </ClientOnly>
              </div>
            </template>

            <template #default="">
              <LayoutInvestorNavigationDropdown />
            </template>
          </CfgDropdown>

          <NuxtLink v-else target="_self" :to="investorAppUrl">
            <CfgButton class="px-4 py-2" flat stroke>
              <span class="whitespace-nowrap">{{ $t('nav.investorApp') }}</span>
            </CfgButton>
          </NuxtLink>
        </div>

        <div
          v-if="isMobileSearchOpened"
          class="mobile-menu absolute border-t border-t-grey-stroke"
          :style="{
            top: `${headerBottomLocation - 1}px`,
            maxHeight: `calc(100% - ${headerBottomLocation}px)`,
          }"
        >
          <FormSearchInput
            v-model="searchValue"
            autofocus
            class="mt-6"
            placeholder="Hledat"
            @clear="searchValue = ''"
            @submit="handleFormSearchInputSubmit()"
          />
        </div>

        <div
          v-if="responsive.desktop || isMobileMenuOpened"
          ref="mobileNavigation"
          class="absolute border-t border-t-grey-stroke"
          :class="{ 'mobile-menu': !responsive.desktop }"
          :style="{
            top: `${headerBottomLocation - 1}px`,
            maxHeight: `calc(100% - ${headerBottomLocation}px)`,
          }"
        >
          <template v-if="!responsive.desktop">
            <LayoutNavigation v-slot="{ menu }">
              <ul class="pt-7">
                <li v-for="({ title, path, dropdownItems, handleClick, dropdownComponent }, index) in menu.filter(({ title }) => responsive.desktop || title !== 'nav.search')" :key="path">
                  <LazyBaseAccordion
                    v-if="dropdownItems?.length || dropdownComponent"
                    v-slot="{ on, activeIdx }"
                    v-model:opened="openedMobileMenuAccordionItem"
                  >
                    <LazyBaseAccordionItem
                      v-if="dropdownItems?.length"
                      :key="title"
                      :index="index"
                      :opened="activeIdx === index"
                      v-on="on"
                    >
                      <template #head>
                        <span class="grow font-display font-highlighted">{{ title }}</span>
                      </template>

                      <ul class="mt-1 space-y-1 pb-2">
                        <li
                          v-for="({ path: childPath, title: childTitle, handleClick: childHandleClick }) in dropdownItems"
                          :key="childPath"
                        >
                          <NuxtLink
                            active-class="text-brand"
                            :aria-label="childTitle"
                            class="sm-text block py-1 text-grey-400"
                            :class="{
                              '!text-brand [&>span]:!font-highlighted': childPath === childPath && $route.path === childPath,
                            }"
                            :to="childPath"
                            @click="childHandleClick?.(); handleCloseMobileMenu()"
                          >
                            <CfgTypography class="!" :size="CfgTypographySizes.xs">
                              {{ childTitle }}
                            </CfgTypography>
                          </NuxtLink>
                        </li>
                      </ul>
                    </LazyBaseAccordionItem>

                    <LazyBaseAccordionItem
                      v-else-if="dropdownComponent"
                      :key="`dropdown-${title}`"
                      :index="index"
                      :opened="activeIdx === index"
                      v-on="on"
                    >
                      <template #head>
                        <span class="grow font-display font-highlighted">{{ title }}</span>
                      </template>

                      <NavBarForCompaniesDropdown @close="handleCloseMobileMenu()" />
                    </LazyBaseAccordionItem>
                  </LazyBaseAccordion>

                  <NuxtLink
                    v-else
                    :aria-label="title"
                    class="flex items-center gap-x-3 py-2 text-sm sm:text-md"
                    :to="path"
                    @click="handleClick?.(); handleCloseMobileMenu()"
                  >
                    <span class="grow font-display font-highlighted text-grey-400">{{ title }}</span>
                  </NuxtLink>
                </li>
              </ul>
            </LayoutNavigation>

            <div class="mb-2 mt-16 flex items-center gap-x-2 bg-[#FAFAFC] p-3 text-secondary">
              <ProfileCircleIcon class="text-[20px]" />

              {{ userName }}
            </div>

            <LayoutInvestorNavigationDropdown />
          </template>
        </div>

        <button
          class="ml-auto flex size-11 items-center justify-center rounded-lg text-brand outline outline-brand xl:hidden"
          @click="handleToggleSearch()"
        >
          <SearchIcon v-if="!isMobileSearchOpened" class="text-[24px]" />

          <XMarkIcon v-else class="text-[24px]" />
        </button>

        <button
          aria-label="Otevřít menu"
          class="ml-6 flex size-11 flex-col items-center justify-center rounded-lg text-brand outline outline-brand xl:hidden"
          type="button"
          @click="handleToggleMenu()"
        >
          <template v-if="!isMobileMenuOpened">
            <MenuIcon class="text-[24px]" />

            <span class="-mt-0.5 text-[12px]">Menu</span>
          </template>

          <XMarkIcon v-else class="text-[24px]" />
        </button>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.mobile-menu {
  position: fixed;
  @apply fixed left-0 w-full bg-white z-sticky px-5 pb-7 overflow-auto;

  .router-link-active > span{
      @apply text-brand
  }
}
</style>

// Dangerous global styles
<style lang="scss">
:root {
  --content-y: 0;

  @screen lg {
    --content-y: 20px;
  }

  @screen xl {
    --content-y: 40px;
  }
}

.page-header {
  @screen lg {
    &.top {
      top: var(--content-y) !important;
    }
  }
}
</style>
