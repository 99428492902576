<script lang="ts" setup>
const isOpened = defineModel<boolean>('isOpened', {
  default: false,
  type: Boolean as PropType<boolean>,
})

const { signUpEmail, formType } = useSignUpIssue()
</script>

<template>
  <LayoutModalSuccess
    v-model:is-opened="isOpened"
    :description="$t(`issue.signUpIssue.${formType}.success.description`, { email: signUpEmail })"
    :title="$t(`issue.signUpIssue.${formType}.success.title`)"
  />
</template>
