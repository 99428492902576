<script lang="ts" setup>
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { Footer } from '~/api/types'
import type { Section } from '~/api/types/generated/components/Section'

const props = defineProps<{
  footer: Footer | null
}>()

const subNavigationGroups = computed(() => props.footer?.sections?.map((section: Section) => ({
  name: section.name,
  routes: section.links.map(link => ({
    label: link.displayName,
    href: link.URL || '#',
    target: link.openInNewWindow ? '_blank' : '_self',
  })),
})) || [])
</script>

<template>
  <div>
    <slot :routes="subNavigationGroups">
      <nav :aria-label="$t('nav.footerAriaLabel')">
        <ul
          class="grid grid-cols-1 gap-12"
          :class="{
            'lg:grid-cols-2': subNavigationGroups.length > 1,
          }"
        >
          <li
            v-for="{ name, routes } in subNavigationGroups"
            :key="name"
          >
            <CfgTypography
              class="text-center lg:text-left"
              :size="CfgTypographySizes.lg"
              tag-name="h2"
            >
              {{ name }}
            </CfgTypography>

            <ul class="mt-4 grid grid-cols-1 gap-x-10 lg:mt-6 lg:grid-cols-2">
              <li
                v-for="{ label, href, target } in routes"
                :key="label"
                class="flex justify-center lg:justify-normal"
              >
                <NuxtLink
                  :href="href"
                  :target="target"
                >
                  <CfgTypography
                    class="block py-[6px] hover:text-brand hover:underline lg:transition-colors lg:duration-300"
                    :size="CfgTypographySizes.small"
                    tag-name="span"
                  >
                    <span class="text-[1.5em] text-brand">
                      >
                    </span>

                    <span>
                      {{ label }}
                    </span>
                  </CfgTypography>
                </NuxtLink>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
.subnavigation-group {
  @screen lg {
    flex-basis: var(--basis, 100%);
  }
}

.routes-group {
  @screen lg {
    columns: var(--columns, 2);
  }
}
</style>
