<script lang="ts" setup>
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'

const isOpened = defineModel<boolean>('isOpened', {
  default: false,
  type: Boolean as PropType<boolean>,
})

const { formType } = useSignUpIssue()
</script>

<template>
  <LayoutModalError
    v-model:opened="isOpened"
    :title="$t('issue.signUpIssue.default.error.title')"
  >
    <template #description>
      <hr class="my-6 h-px dark:text-grey-200/50">

      <div class="mt-4 flex flex-col gap-y-4">
        <CfgTypography class="block text-center !font-highlighted" :size="CfgTypographySizes.md">
          {{ $t(`issue.signUpIssue.${formType}.error.description`) }}
        </CfgTypography>

        <ContactManager />
      </div>
    </template>
  </LayoutModalError>
</template>
