<script setup lang="ts">
const { public: { investorAppUrl } } = useRuntimeConfig()

const items = [
  { link: `${investorAppUrl}/portfolio`, text: 'Portfolio' },
  { link: `${investorAppUrl}/returns`, text: 'Výplaty' },
  { link: `${investorAppUrl}/portfolio?events`, text: 'Aktuality' },
  { link: `${investorAppUrl}/settings`, text: 'Nastavení' },
  { link: `${investorAppUrl}/auth/login?logout`, text: 'Odhlásit se' },
]
</script>

<template>
  <ul class="min-w-56 xl:p-4">
    <li
      v-for="item, index in items"
      :key="index"
      class="xl:border-b xl:border-grey-stroke xl:text-brand"
    >
      <NuxtLink class="block py-2 xl:py-3" target="_self" :to="item.link">
        {{ item.text }}
      </NuxtLink>
    </li>
  </ul>
</template>
