<script lang="ts" setup>
import { breakpointsTailwind } from '@vueuse/core'
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { MailIcon, PhoneIcon } from '@cfgtech/icons'
import type { Footer } from '~/api/types'
import { defaultManager } from '~/constants/content'

defineProps<{
  footer: Footer | null
}>()

const breakpoints = useBreakpoints(breakpointsTailwind)
const isDesktop = breakpoints.greater('md')

const isLargeTextOpened = ref(false)
</script>

<template>
  <footer>
    <div class="container">
      <div class="flex flex-col items-center lg:flex-row lg:items-start lg:gap-x-10 2xl:gap-x-40">
        <div class="order-2 flex flex-col items-center gap-y-8 lg:order-1 lg:items-start lg:justify-between lg:gap-y-1">
          <NuxtLink class="mx-auto lg:w-full" to="/">
            <AppLogo />
          </NuxtLink>

          <AppSocialMedia class="text-[22px] text-grey-200 lg:hidden" />

          <CfgTypography
            class="order-4 max-w-[200px] pt-1 text-center text-grey-300 lg:order-none lg:mt-2 lg:text-left"
            :size="CfgTypographySizes.small"
            tag-name="p"
          >
            Klub&nbsp;investorů&nbsp;a&nbsp;traderů&nbsp;s.r.o.
            Vinohradská&nbsp;2396/184
            130&nbsp;00&nbsp;PRAHA&nbsp;3&nbsp;-&nbsp;Vinohrady
            IČ:&nbsp;241&nbsp;61&nbsp;179
            Společnost&nbsp;vedená&nbsp;pod&nbsp;spisovou&nbsp;značkou
            C&nbsp;184304&nbsp;u&nbsp;Městského&nbsp;soudu&nbsp;v&nbsp;Praze
          </CfgTypography>

          <!-- CONTACT -->
          <CfgTypography
            class="order-3 space-y-4 lg:order-none lg:pt-12 2xl:space-y-5"
            :size="CfgTypographySizes.md"
            tag-name="div"
          >
            <a
              class="flex items-center justify-center gap-x-3 text-brand underline lg:justify-start lg:transition-colors lg:duration-300 lg:hover:no-underline"
              :href="defaultManager.phoneHref"
            >
              <ClientOnly><PhoneIcon class="text-lg" /></ClientOnly>

              <span>{{ defaultManager.phoneWithoutPrefix.replace(/ /g, '&nbsp;') }}</span>
            </a>

            <a
              class="flex items-center justify-center gap-x-3 text-brand underline lg:justify-start lg:transition-colors lg:duration-300 lg:hover:no-underline"
              href="mailto:info@srovnavacdluhopisu.cz"
            >
              <ClientOnly>
                <MailIcon class="text-lg" />
              </ClientOnly>

              <span>info@srovnavacdluhopisu.cz</span>
            </a>
          </CfgTypography>
        </div>

        <!-- lg:pt-3 set is one line with logo -->
        <div class="lg-order-2 order-1 mb-14 flex grow lg:mb-0 lg:pt-3 xl:pl-10">
          <LayoutSubNavigation :footer="footer" />
        </div>
      </div>

      <!-- LARGE TEXT -->
      <LazyBaseCollapsable
        v-model:is-opened="isLargeTextOpened"
        :closed-height="200"
        :disabled="isDesktop"
      >
        <CfgTypography
          class="mt-10 text-center text-grey-300 lg:mt-12 lg:text-left"
          :size="CfgTypographySizes.micro"
          tag-name="p"
        >
          {{ $t('common.footerDisclaimer') }}

          <a
            class="break-all"
            href="https://www.cnb.cz/cs/spotrebitel/ochrana_spotrebitele/desatero_investor_podnik_dluhopisy.html"
            target="_blank"
          >
            https://www.cnb.cz/cs/spotrebitel/ochrana_spotrebitele/desatero_investor_podnik_dluhopisy.html
          </a>
          .
        </CfgTypography>
      </LazyBaseCollapsable>

      <!-- COPYRIGHT ROW -->
      <div class="mt-8 flex flex-col items-center gap-y-10 border-t border-grey-stroke pb-5 pt-10 md:flex-row md:items-center lg:mt-7 lg:py-5">
        <CfgTypography
          class="flex grow flex-col items-center gap-x-6 gap-y-5 text-grey-400 lg:flex-row"
          :size="CfgTypographySizes.sm"
          tag-name="div"
        >
          <p class="order-4 mt-10 lg:order-none lg:mt-0">
            © 2020-2024 Srovnávač dluhopisů
          </p>

          <div class="flex flex-wrap justify-center gap-x-5 gap-y-3">
            <a
              class="text-brand underline lg:transition-colors lg:duration-300 lg:hover:no-underline"
              href="https://srovnavacdluhopisu.cz/informace-o-zpracovani-osobnich-udaju/"
            >
              Informace o zpracování osobních údajů
            </a>

            <a
              class="text-brand underline lg:transition-colors lg:duration-300 lg:hover:no-underline"
              href="https://srovnavacdluhopisu.cz/principy-fungovani/"
            >
              Principy fungování
            </a>

            <a
              class="text-brand underline lg:transition-colors lg:duration-300 lg:hover:no-underline"
              href="javascript:Cookiebot.renew()"
            >
              Zásady cookies
            </a>
          </div>
        </CfgTypography>

        <div class="hidden text-md lg:block">
          <AppSocialMedia class="text-grey-200" />
        </div>
      </div>
    </div>
  </footer>
</template>
