<script lang="ts" setup>
import { CfgForm, FormControlNames, useForm } from '@cfgtech/ui'
import type { CfgFormExposed, FormResults, FormSchema } from '@cfgtech/ui'
import type { AddIssueSchema } from './types'

const props = defineProps<{
  isLoading: boolean
  hideIssueInput?: boolean
}>()

const emit = defineEmits<{
  submit: [results: FormResults<AddIssueSchema>]
}>()

const { t } = useI18n()

const formRef = ref<CfgFormExposed | null>(null)
const { formType } = useSignUpIssue()

const { requiredValidator, emailValidator, phoneValidator } = useTranslatedValidators()

function formSchema(): FormSchema<AddIssueSchema> {
  const schema: Partial<AddIssueSchema> = {}

  if (!props.hideIssueInput) {
    Object.assign(schema, {
      nameOrIsin: {
        control: FormControlNames.Input,
        placeholder: t('issue.signUpIssue.form.nameOrIsin'),
        initialFocused: true,
        required: true,
        value: '',
        validators: [requiredValidator],
      },
    })
  }

  Object.assign(schema, {
    email: {
      control: FormControlNames.Input,
      type: 'email',
      placeholder: t('issue.signUpIssue.form.email'),
      initialFocused: false,
      required: true,
      value: '',
      validators: [requiredValidator, emailValidator],
    },
    phone: {
      control: FormControlNames.Input,
      type: 'tel',
      placeholder: t('issue.signUpIssue.form.phone'),
      initialFocused: false,
      required: false,
      value: '',
      validators: [(value: string) => value === '' ? true : phoneValidator(value)],
    },

    gdpr: {
      control: FormControlNames.Checkbox,
      placeholder: t('issue.signUpIssue.form.gdpr'),
      value: false,
      required: true,
      validators: [(value: boolean) => value || t('common.validators.gdprConfirm')],
    },
  })

  return schema as FormSchema<AddIssueSchema>
}

const { schema, results, resetData } = useForm<AddIssueSchema>(formSchema())

defineExpose({
  resetForm() {
    resetData()
    formRef.value?.resetForm()
  },
})

function handleFormSubmit() {
  emit('submit', results.value)
}
</script>

<template>
  <CfgForm
    ref="formRef"
    :button-label="$t(`issue.signUpIssue.${formType}.submit`)"
    :loading="isLoading"
    :schema="schema"
    @submit="handleFormSubmit();"
  />
</template>
