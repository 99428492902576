<script setup lang="ts">
const overlay = useOverlay()

const isLocked = useScrollLock(document)

watchEffect(() => {
  isLocked.value = overlay.value
})
</script>

<template>
  <!-- eslint-disable-next-line vue/quote-props -->
  <div class="fixed inset-0 z-[9] hidden h-screen w-screen bg-black/50" :class="{ '!block': overlay }" />
</template>
