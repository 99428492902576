<script setup lang="ts">
import { CfgButton, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { api } from '~/api/Api'
import type { MetaSocial } from '~/api/types/generated/components/MetaSocial'
import { socialNames } from '~/constants/seo'
import type { AppProvide } from '~/types/app.provide'

withDefaults(
  defineProps<{
    isWhyUsHidden?: boolean
    isWroteAboutUsHidden?: boolean
    isContactUsHidden?: boolean
  }>(),
  {
    isWhyUsHidden: false,
    isWroteAboutUsHidden: false,
    isContactUsHidden: false,
  },
)

// composables
const route = useRoute()

const { isIssuerSectionBannerShown, handleBannerClick } = useIssuerSectionBanner()

// useAsyncData
const { data } = await useAsyncData(
  'app-data',
  async () => {
    const [footerData] = await Promise.allSettled([
      api.footerModule.getFooterList(),
    ])

    return { footerData: footerData.status === 'fulfilled' ? footerData.value : null }
  },
)

// data
const { t } = useI18n()

const config = useRuntimeConfig()

const metaDefaultSocial: MetaSocial[] = Object.values(socialNames).map((socialName): MetaSocial => ({
  id: 0,
  socialNetwork: socialName,
  title: t('common.seo.title'),
  description: t('common.seo.description'),
  imageAlternativeText: t('common.seo.imageAltHomePage'),
}))

useSeo({
  id: 0,
  metaSocial: metaDefaultSocial,
})

// composables
useHideSmartsupp()

provide<AppProvide['scrollTo']>('scrollTo', {
  newsletter() {
    useScrollToBlock('newsletter')
  },
})

watchEffect(() => {
  const decodedFullPath = decodeURI(route.fullPath)

  useHead({
    link: [
      {
        rel: 'canonical',
        href: `${config.app.ORIGIN}${decodedFullPath}`.replace(/\/$/, ''),
      },
    ],
  })
})

if (process.client) {
  // This event will be triggered when "Failed to fetch dynamically imported module" occured.
  // https://vitejs.dev/guide/build#load-error-handling
  window.addEventListener('vite:preloadError', (event: any) => {
    event.preventDefault()
    window.location.reload()
  })
}
</script>

<template>
  <div class="relative max-w-full">
    <NuxtLoadingIndicator
      class="bg-brand/50"
      color=""
      :height="4"
    />

    <div class="relative flex min-h-screen max-w-full flex-col justify-between">
      <LayoutIssuerSectionBanner v-if="isIssuerSectionBannerShown" @click="handleBannerClick" />

      <LayoutHeader :is-issuer-section-banner-shown="isIssuerSectionBannerShown" />

      <div class="page relative z-0 max-w-full grow">
        <AppOverlay />

        <div class="flex max-w-full flex-col first:w-full first:flex-1">
          <div>
            <slot />
          </div>

          <section v-if="route.fullPath !== '/' && !isWhyUsHidden">
            <div class="container my-20">
              <LazyBaseBenefitsNew />
            </div>
          </section>

          <section v-if="!isWroteAboutUsHidden" class="container mb-16">
            <LazyLayoutWroteAboutUs />
          </section>

          <section v-if="!isContactUsHidden" class="container">
            <div class="overflow-hidden rounded-xl shadow-light">
              <LazyLayoutContactForm id="newsletter" />
            </div>
          </section>
        </div>
      </div>

      <LayoutFooter class="bg-white pt-20" :footer="data?.footerData || null" />
    </div>

    <ModalSignUpIssue />

    <ContactIssuerForwardDialog />

    <!-- CHAT WITH US BUTTON -->
    <div class="transition-bottom fixed bottom-5 right-2 z-above-modal size-max duration-200">
      <GTMProvider v-slot="{ events, locations }">
        <CfgButton
          class="rounded-full shadow-md lg:right-7"
          icon-placement="right"
          :label="$t('homepage.needHelp.title')"
          variant="primary"
          @click="$openSmartsuppDialog(); events.onCallpageMainOpenEvent(locations.Homepage);"
        >
          <span class="-m-1 flex items-center gap-x-2">
            <CfgTypography :size="CfgTypographySizes.md">
              {{ $t('homepage.needHelp.title') }}
            </CfgTypography>

            <NuxtImg
              alt="Manager Profile Photo"
              class="rounded-full"
              format="avif,webp"
              height="32"
              src="/img/chat-button-photo.jpeg"
              width="32"
            />
          </span>
        </CfgButton>
      </GTMProvider>
    </div>
  </div>
</template>

<style lang="scss">
.page {
  // setted in LayoutHeader.vue
  min-height: calc(100vh - var(--header-height) - var(--mobile-navigation-height, 1px));
  display: flex;

  & > * {
    flex: 1 !important;
  }
}
</style>
